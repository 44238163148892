import { useState } from 'react'
import { Dialog as MuiDialog } from '@mui/material'

import { IconButton, Icons } from '..'

export function Dialog({ children, onClose, open, hideCloseButton, ...props }) {
  return (
    <MuiDialog onClose={onClose} open={open} props={props} fullWidth>
      {!hideCloseButton && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Icons.Close />
        </IconButton>
      )}
      {children}
    </MuiDialog>
  )
}

export const useDialog = (dialogProps = {}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!setIsOpen)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  return [
    {
      Dialog,
      dialogProps: {
        onClose: close,
        open: isOpen,
        maxWidth: 'sm',
        fullWidth: true,
        p: 4,
        m: 0,
        ...dialogProps,
      },
    },
    { toggle, open, close },
  ]
}
