import { Icons } from '../../../components'
import { showUnfinishedFeatures, onlyShowInDev } from '../showUnfinishedFeatures'
import * as BankTransfer from './bank-transfer'
import * as Cheque from './cheque'
import * as CreditCard from './credit-card'
import * as DafTransfer from './daf-transfer'
import * as ETransfer from './e-transfer'
import * as Securities from './securities'

export const PAYMENT_METHOD_TYPE = {
  wallet: 'wallet',
  creditCard: 'credit-card',
  bankTransfer: 'bank-transfer',
  eTransfer: 'e-transfer',
  cheque: 'cheque',
  dafTransfer: 'daf-transfer',
  securities: 'securities',
}

export const PaymentMethodsMeta = {
  [PAYMENT_METHOD_TYPE.wallet]: {
    paymentClearingType: 'sync',
    supportsRecurring: true,
    supportsScheduled: true,
    getPaymentValues: async ({ values }) => values,
    createContributions: async () => [],
  },
  [PAYMENT_METHOD_TYPE.creditCard]: {
    icon: <Icons.CreditCard />,
    label: 'Credit Card',
    helperText: 'Standard credit card fees will apply.',

    paymentClearingType: 'sync',
    supportsRecurring: true,
    supportsScheduled: true,
    PaymentUI: CreditCard.PaymentUI,
    getPaymentValues: CreditCard.getPaymentValues,
    createContributions: CreditCard.createContributions,
    SuccessUI: CreditCard.SuccessUI,
  },
  [PAYMENT_METHOD_TYPE.bankTransfer]: {
    icon: <Icons.BuildingColumns />,
    label: 'Direct Debit',
    helperText: 'A hassle-free way to give with no fees',

    paymentClearingType: 'async',
    supportsRecurring: true,
    supportsScheduled: true,
    PaymentUI: BankTransfer.PaymentUI,
    getPaymentValues: BankTransfer.getPaymentValues,
    createContributions: BankTransfer.createContributions,
    SuccessUI: BankTransfer.SuccessUI,
  },
  [PAYMENT_METHOD_TYPE.eTransfer]: {
    icon: <Icons.CircleDollar />,
    label: 'E-Transfer',
    helperText: 'A simple transaction from your online banking platform',

    paymentClearingType: 'manual',
    supportsRecurring: false,
    supportsScheduled: false,
    PaymentUI: ETransfer.PaymentUI,
    getPaymentValues: ETransfer.getPaymentValues,
    createContributions: ETransfer.createContributions,
    SuccessUI: ETransfer.SuccessUI,
  },
  [PAYMENT_METHOD_TYPE.cheque]: {
    icon: <Icons.MoneyCheckDollar />,
    label: 'Cheque',
    helperText: 'Send money through the post',

    paymentClearingType: 'manual',
    supportsRecurring: false,
    supportsScheduled: false,
    PaymentUI: Cheque.PaymentUI,
    getPaymentValues: Cheque.getPaymentValues,
    createContributions: Cheque.createContributions,
    SuccessUI: Cheque.SuccessUI,
  },
  [PAYMENT_METHOD_TYPE.dafTransfer]: {
    icon: <Icons.DafTransfer />,
    label: 'DAF Transfer',
    helperText: 'Funds from Donor Advised Fund (DAF) or Foundation',

    paymentClearingType: 'manual',
    supportsRecurring: false,
    supportsScheduled: false,
    PaymentUI: DafTransfer.PaymentUI,
    getPaymentValues: DafTransfer.getPaymentValues,
    createContributions: DafTransfer.createContributions,
    SuccessUI: DafTransfer.SuccessUI,
  },
  [PAYMENT_METHOD_TYPE.securities]: {
    icon: <Icons.Securities />,
    label: 'Securities',
    helperText: 'Gift of Publicly Traded Securities',

    amountType: 'securities',
    paymentClearingType: 'manual',
    supportsRecurring: false,
    supportsScheduled: false,
    PaymentUI: Securities.PaymentUI,
    getPaymentValues: Securities.getPaymentValues,
    createContributions: Securities.createContributions,
    SuccessUI: Securities.SuccessUI,
  },
}

export const paymentMethods = [
  {
    value: PAYMENT_METHOD_TYPE.creditCard,
    ...PaymentMethodsMeta[PAYMENT_METHOD_TYPE.creditCard],
  },
  ...(showUnfinishedFeatures
    ? [
        {
          value: PAYMENT_METHOD_TYPE.eTransfer,
          ...PaymentMethodsMeta[PAYMENT_METHOD_TYPE.eTransfer],
        },
        {
          value: PAYMENT_METHOD_TYPE.cheque,
          ...PaymentMethodsMeta[PAYMENT_METHOD_TYPE.cheque],
        },
        {
          value: PAYMENT_METHOD_TYPE.dafTransfer,
          ...PaymentMethodsMeta[PAYMENT_METHOD_TYPE.dafTransfer],
        },
      ]
    : []),
  ...(onlyShowInDev
    ? [
        {
          value: PAYMENT_METHOD_TYPE.bankTransfer,
          ...PaymentMethodsMeta[PAYMENT_METHOD_TYPE.bankTransfer],
        },
        {
          value: PAYMENT_METHOD_TYPE.securities,
          ...PaymentMethodsMeta[PAYMENT_METHOD_TYPE.securities],
        },
      ]
    : []),
]
