import { gql } from '@apollo/client'

export const signUp = gql`
  mutation SignUp($data: SignUpInput!) {
    signUp(data: $data)
  }
`

export const requestPasswordReset = gql`
  mutation RequestPasswordReset($data: BasicEmailInput!) {
    requestPasswordReset(data: $data)
  }
`

export const resetPassword = gql`
  mutation ResetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data)
  }
`

export const updatePassword = gql`
  mutation UpdatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data)
  }
`

export const addNewEmail = gql`
  mutation AddNewEmail($data: AddEmailInput!) {
    addNewEmail(data: $data) {
      id
    }
  }
`

export const confirmAddedEmail = gql`
  mutation confirmAddedEmail($data: String!) {
    confirmAddedEmail(data: $data) {
      id
    }
  }
`

export const updateEmail = gql`
  mutation updateEmail($data: ChangeEmailInput!) {
    updateEmail(data: $data) {
      id
      email
    }
  }
`

export const confirmEmail = gql`
  mutation confirmEmail($data: EmailConfirmationInput!) {
    confirmEmail(data: $data)
  }
`

export const updateFirstLogin = gql`
  mutation updateFirstLogin($data: Boolean!) {
    updateFirstLogin(data: $data)
  }
`

export const updateProfile = gql`
  mutation updateProfile($data: UpdateProfileInput!) {
    updateProfile(data: $data) {
      id
      firstName
      lastName
      middleInitials
      corporateName
      phone
      address {
        lineOne
        lineTwo
        postalCode
        province
        city
        country
      }
    }
  }
`

export const updateProfileWithoutAddress = gql`
  mutation updateProfile($data: UpdateProfileInput!) {
    updateProfile(data: $data) {
      id
      firstName
      lastName
      middleInitials
      corporateName
      phone
    }
  }
`

export const favouriteACharity = gql`
  mutation favouriteACharity($charityId: String!) {
    favouriteACharity(charityId: $charityId) {
      id
    }
  }
`

export const unfavouriteACharity = gql`
  mutation unfavouriteACharity($charityId: String!) {
    unfavouriteACharity(charityId: $charityId) {
      id
    }
  }
`

export const charityUserSignUp = gql`
  mutation charityUserSignUp($data: CharityUserSignUpInput!) {
    charityUserSignUp(data: $data)
  }
`

export const guestSignUp = gql`
  mutation guestSignUp($data: GuestSignUpInput!) {
    guestSignUp(data: $data)
  }
`

export const updateGuestProfile = gql`
  mutation updateGuestProfile($userId: Float!, $data: UpdateProfileInput!) {
    updateGuestProfile(userId: $userId, data: $data) {
      id
      firstName
      lastName
      middleInitials
      corporateName
      phone
      address {
        lineOne
        lineTwo
        postalCode
        province
        city
        country
      }
    }
  }
`

export const requestAccountDeletion = gql`
  mutation requestAccountDeletion {
    requestAccountDeletion
  }
`
