import { useQuery, useMutation } from '@apollo/client'
import { useState, useEffect } from 'react'
import { Confirm } from 'react-admin'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { Button, Card, CardBox, CardActions, CardContent, Icons, Stack, Text, useAlert } from '../../components'
import { queries, mutations } from '../../graphql'

export function ManageGivingFundDeletion(props) {
  const { manageGivingFundDeletionRef } = props
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const [requestAccDeletion, { data, error }] = useMutation(mutations.user.requestAccountDeletion)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = async () => {
    try {
      const result = await requestAccDeletion()
      if (result.data.requestAccountDeletion) {
        console.log('Account deletion requested successfully')
      } else {
        console.log('Account deletion failed')
      }
    } catch (err) {
      console.error('Error requesting account deletion:', err)
    }
    setOpen(false)
  }

  return (
    <Stack ref={manageGivingFundDeletionRef}>
      <CardBox>
        <Stack direction="row">
          <Text.H4>Delete Giving Fund</Text.H4>
        </Stack>
        <br />
        <Stack>
          <Text.Body>
            We&apos;re sorry to see you go! Deleting your Giving Fund will permanently remove all your associated
            personal data and cannot be undone.
          </Text.Body>
          <Text.Body>If you&apos;re sure, please confirm by clicking the button below.</Text.Body>
          <br />
          <Text.H7>Need help? Contact us at support@givewise.ca for assistance.</Text.H7>
          <Button onClick={handleOpen}>Delete My Account</Button>
        </Stack>
        <br />
        <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your Giving Fund deletion request has been received. Your Giving Fund and all associated personal data
              will be permanently deleted within 72 hours.
            </DialogContentText>
            <br />
            <DialogContentText id="alert-dialog-description">
              <Text.H7>
                If you have any questions or change your mind, please contact us at support@givewise.ca before the
                process is completed.
              </Text.H7>
            </DialogContentText>
            <br />
            <Button onClick={handleClose}>OK</Button>
          </DialogContent>
        </Dialog>
      </CardBox>
    </Stack>
  )
}
