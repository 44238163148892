import { useQuery } from '@apollo/client'
import { CORPORATION_USER_TYPE } from 'constants'
import { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { onlyShowInDev, showUnfinishedFeatures } from '../gift/showUnfinishedFeatures'
import {
  Button,
  Container,
  Dialog,
  Icons,
  Link,
  Loading,
  Menu,
  MenuItem,
  Notice,
  Row,
  Stack,
  Text,
  Typography,
  useDialog,
} from '../../components'
import {
  AddEmailForm,
  ChangeEmailForm,
  ChangePasswordForm,
  ProfileForm,
  ManageCreditCards,
  ManageBankAccounts,
  ManageZumBankAccounts,
} from '.'
import { queries } from '../../graphql'
import { userName } from '../../utils'
import palette from '../../palette.json'
import { CharityUserProfileForm } from './charity-user-profile-form'
import { ManageGivingFundDeletion } from './manage-giving-fund-deletion'

export function Profile() {
  const [anchorElOpen, setAnchorElOpen] = useState(null)
  const { state, hash } = useLocation()
  const manageCreditCardsRef = useRef(null)
  const manageBankAccountsRef = useRef(null)
  const manageGivingFundDeletionRef = useRef(null)
  const { afterLogin } = state ?? {}
  const [{ dialogProps: passwordDialogProps }, { open: openPasswordDialog, close: closePasswordDialog }] = useDialog()
  const [{ dialogProps: emailDialogProps }, { open: openEmailDialog, close: closeEmailDialog }] = useDialog()
  const [{ dialogProps: addEmailDialogProps }, { open: openAddEmailDialog, close: closeAddEmailDialog }] = useDialog()
  const { data: { me } = {}, loading } = useQuery(queries.user.me)
  const { profile, email: primaryEmail, userEmails } = me ?? {}
  const { address } = profile ?? {}
  const firstLogin = !address && profile.userType !== 'charity'
  const profileName = userName(profile)
  const isDonor = me && profile && profile.userType !== 'charity'

  const handleOpenUserMenu = (event) => {
    setAnchorElOpen(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElOpen(null)
  }

  useEffect(() => {
    if (hash === '#manage-credit-cards' && manageCreditCardsRef.current) {
      manageCreditCardsRef.current.scrollIntoView()
    } else if (hash === '#manage-bank-accounts' && manageBankAccountsRef.current) {
      manageBankAccountsRef.current.scrollIntoView()
    } else if (hash === '#manage-giving-fund-deletion' && manageGivingFundDeletionRef.current) {
      manageGivingFundDeletionRef.current.scrollIntoView()
    }
  }, [hash, manageCreditCardsRef.current, manageBankAccountsRef.current, manageGivingFundDeletionRef.current])

  if (loading) return <Loading />
  return (
    <>
      <Container maxWidth="lg">
        <Stack spacing={4} sx={{ mt: '2rem' }}>
          <Row sx={{ width: '100%' }} justifyContent="space-between" alignItems="center">
            <Stack>
              <Text.H4 data-testid="profileName">{profileName}</Text.H4>
              {profile?.userType === CORPORATION_USER_TYPE && (
                <Text.Body>{`Primary Contact Person: ${profile.firstName} ${profile.middleInitials || ''} ${
                  profile.lastName
                }`}</Text.Body>
              )}
              <Text.Body data-testid="profileEmail">Primary email: {primaryEmail}</Text.Body>
              <Text.AltBody>
                To request changes to your{' '}
                {profile.userType === CORPORATION_USER_TYPE ? 'corporate name or primary contact person' : 'user name'},
                please contact: <Link href="mailto:support@givewise.ca">support@givewise.ca</Link>
              </Text.AltBody>
            </Stack>
            {!firstLogin && (
              <Stack alignItems="flex-end">
                <Button
                  data-testid="horizIcon"
                  variant="contained"
                  onClick={handleOpenUserMenu}
                  sx={{
                    color: palette.dark.blue,
                    width: '60px!important',
                    height: '60px',
                    borderRadius: '40px',
                  }}
                >
                  <Icons.MoreHoriz fontSize="large" />
                  <Text.SR>profile options menu</Text.SR>
                </Button>
                <Menu
                  sx={{ mt: '65px' }}
                  id="profileOptionsMenu"
                  anchorEl={anchorElOpen}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElOpen)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem key="addEmail" onClick={handleCloseUserMenu}>
                    <Typography onClick={openAddEmailDialog} data-testid="addEmailButton">
                      Add Email Address
                    </Typography>
                  </MenuItem>
                  {userEmails.length > 1 && (
                    <MenuItem key="changeEmail" onClick={handleCloseUserMenu}>
                      <Typography onClick={openEmailDialog} data-testid="changeEmailButton">
                        Change Primary Email Address
                      </Typography>
                    </MenuItem>
                  )}
                  <MenuItem key="changePassword" onClick={handleCloseUserMenu}>
                    <Typography onClick={openPasswordDialog} data-testid="changePasswordButton">
                      Change Password
                    </Typography>
                  </MenuItem>
                  {profile.userType !== 'charity' ? (
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Link to="/successor" sx={{ textDecoration: 'none', fontWeight: 'normal' }}>
                        Successor Instructions
                      </Link>
                    </MenuItem>
                  ) : (
                    ''
                  )}
                </Menu>
              </Stack>
            )}
          </Row>
          {firstLogin && (
            <Notice>
              <Text.H4 data-testid="welcomeTitle">Welcome to GiveWise!</Text.H4>
              <Text.Body data-testid="welcomeContent">
                You must update your profile with your address and phone number in order to complete your registration
              </Text.Body>
            </Notice>
          )}
          {isDonor ? (
            <ProfileForm profile={profile} firstLogin={firstLogin} afterLogin={afterLogin} />
          ) : (
            <CharityUserProfileForm
              profile={profile}
              charityUser={me.charityUsers.find((cu) => cu.userId === me.id)}
              firstLogin={firstLogin}
              afterLogin={afterLogin}
            />
          )}
          {isDonor && <ManageCreditCards manageCreditCardsRef={manageCreditCardsRef} />}
          {isDonor && <ManageZumBankAccounts manageBankAccountsRef={manageBankAccountsRef} />}
          {isDonor && <ManageGivingFundDeletion manageGivingFundDeletionRef={manageGivingFundDeletionRef} />}
        </Stack>
      </Container>

      <Dialog {...addEmailDialogProps}>
        <AddEmailForm close={closeAddEmailDialog} />
      </Dialog>
      <Dialog {...emailDialogProps}>
        <ChangeEmailForm currentEmail={primaryEmail} availableEmails={userEmails} close={closeEmailDialog} />
      </Dialog>
      <Dialog {...passwordDialogProps}>
        <ChangePasswordForm close={closePasswordDialog} />
      </Dialog>
    </>
  )
}
