import { useApolloClient } from '@apollo/client'
import { Box, Divider, Typography } from '@mui/material'
import { subtract } from 'dinero.js'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import invariant from 'invariant'
import { useEffect, useState } from 'react'

import {
  Button,
  Checkbox,
  Container,
  ContentBox,
  FormControl,
  FormControlLabel,
  Icons,
  Stack,
  Text,
  insufficientFundsExtraMessage,
  longWait,
  useAlert,
} from '../../components'
import { mutations, queries } from '../../graphql'
import { fromAmountField, join, toAmountString, toDateString } from '../../utils'
import { cadFromDecimal, decimalFromCad } from './dinero-cad'
import { calculateGiftAmount } from './gift-details-math'
import { PaymentMethodsMeta } from './payment'
import { GuestPaymentMethodsMeta } from './payment-guest/index'
import { onlyShowInDev } from './showUnfinishedFeatures'

export function ConfirmStep({ charity, defaultFund, stepperState, dispatchStep }) {
  useEffect(() => {
    if (onlyShowInDev) console.log(`confirm stepperState: ${JSON.stringify(stepperState, null, 2)}`)
  }, [stepperState])

  const client = useApolloClient()
  const [{ Alert, alertProps }, { setAlert }] = useAlert()

  const { register, handleSubmit, formState, getValues, setValue, watch, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      anonymous: false,
    },
  })
  const { isSubmitting, errors = {} } = formState

  const getPurposeOfGiftId = (purposeOfGrant) => {
    if (charity?.purposeOfGifts) {
      const purpose = charity.purposeOfGifts.find((p) => p.reason === purposeOfGrant)
      if (purpose) return purpose.id
    }
    return null
  }

  const [contributionCreated, setContributionCreated] = useState(false)

  const onSubmitUser = async (anonymous) => {
    try {
      if (!defaultFund) throw new Error(`No default fund to contribute to`)
      const fund = defaultFund

      const {
        amountType,
        securities,
        isRecurring,
        recurringInterval,
        processingDate,
        purposeOfGrant,
        purposeOfGrantNote,
        selfAttestation,
      } = stepperState.giftDetails
      const amounts = calculateGiftAmount(stepperState.giftDetails)
      const { paymentMethodType } = stepperState.payment

      const PaymentMethodMeta = PaymentMethodsMeta[paymentMethodType] ?? {}

      let contributions
      if (paymentMethodType !== 'wallet') {
        try {
          invariant(
            PaymentMethodMeta.createContributions,
            `Payment Method type ${paymentMethodType} is missing createContributions`
          )

          if (!contributionCreated) {
            contributions = await PaymentMethodMeta.createContributions({
              client,
              fundId: Number(fund.id),
              giftDetails: stepperState.giftDetails,
              payment: stepperState.payment,
            })
            setContributionCreated(true)
          }
        } catch (e) {
          setAlert({
            message: `Failed to create a contribution: ${e.message ?? ''}`,
            error: e.message,
            severity: 'error',
          })
          return
        }

        if (
          contributions.some(
            (contribution) =>
              (contribution?.state !== 'cleared' && contribution?.state !== 'scheduled') ||
              contribution?.netAmount == null ||
              contribution?.fee == null
          )
        ) {
          setAlert({
            message:
              'The payment has not finished processing. Check your activity page and try giving from your wallet when the contribution has been processed.',
            extraMessage: (
              <Text.AltBody>
                Check your <Link to={`/funds/${fund.id}/activity`}>activity page</Link> and{' '}
                <Link to="/">give to the charity</Link> when the contribution has been processed.
              </Text.AltBody>
            ),
            severity: 'error',
          })
          return
        }
      }

      const MAX_RETRIES = 3
      const handlGrantRequestCreation = async (contributionsData) => {
        let grantRequests
        if (paymentMethodType === 'wallet') {
          await client.mutate({
            mutation: mutations.grantRequests.createGrantRequest,
            variables: {
              data: {
                charityId: charity.id,
                fundId: Number(fund.id),
                amount: amounts.amount,
                tip: amounts.tip,
                grantNote: join([purposeOfGrant, purposeOfGrantNote], ': '),
                isRecurring,
                recurringInterval: isRecurring ? recurringInterval : null,
                purposeOfGiftId: getPurposeOfGiftId(purposeOfGrant),
                processingDate,
                anonymous,
                selfAttestation,
                isStepperFlow: true,
              },
            },
            refetchQueries: [
              { query: queries.funds.myFund, variables: { fundId: Number(fund.id) } },
              { query: queries.funds.myFundActivity, variables: { fundId: Number(fund.id) } },
              { query: queries.charities.charity, variables: { fundId: Number(fund.id), charityId: charity.id } },
              { query: queries.funds.myRecurring, variables: { fundId: Number(fund.id) } },
            ],
            awaitRefetchQueries: true,
          })
        } else if (['e-transfer', 'cheque', 'daf-transfer'].includes(paymentMethodType)) {
          grantRequests = await Promise.all(
            contributionsData.map(
              (contribution) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve({
                      data: {
                        createGrantRequest: {
                          id: Math.floor(Math.random() * 10000), // Fake ID
                          amount: contribution.amount, // Use the contribution amount
                          status: 'success', // Example status
                          note: 'This is a mocked grant request.', // Example note
                        },
                      },
                    })
                  }, 100) // Simulate network delay
                })
            )
          )
        } else {
          grantRequests = await Promise.all(
            contributionsData.map((contribution) =>
              client.mutate({
                mutation: mutations.grantRequests.createGrantRequest,
                variables: {
                  data: {
                    charityId: charity.id,
                    fundId: Number(fund.id),
                    amount:
                      amountType === 'securities'
                        ? contribution.amount
                        : decimalFromCad(
                            subtract(
                              cadFromDecimal({ amount: amounts.amount }),
                              cadFromDecimal({ amount: contribution.fee })
                            )
                          ),
                    tip: amounts.tip,
                    grantNote: join([purposeOfGrant, purposeOfGrantNote], ': '),
                    isRecurring,
                    recurringInterval: isRecurring ? recurringInterval : null,
                    purposeOfGiftId: getPurposeOfGiftId(purposeOfGrant),
                    processingDate,
                    anonymous,
                    selfAttestation,
                    isStepperFlow: true,
                  },
                },
                refetchQueries: [
                  { query: queries.funds.myFund, variables: { fundId: Number(fund.id) } },
                  { query: queries.funds.myFundActivity, variables: { fundId: Number(fund.id) } },
                  { query: queries.charities.charity, variables: { fundId: Number(fund.id), charityId: charity.id } },
                  { query: queries.funds.myRecurring, variables: { fundId: Number(fund.id) } },
                ],
                awaitRefetchQueries: true,
              })
            )
          )
        }
        return grantRequests
      }

      const createGrantRequestWithRetry = async (retriesLeft) => {
        try {
          return await handlGrantRequestCreation(contributions) // Attempt to create the grant request
        } catch (e) {
          if (retriesLeft > 0) {
            console.warn(`Retrying grant request... (Retries left: ${retriesLeft})`)
            return createGrantRequestWithRetry(retriesLeft - 1) // Recursively retry
          }
          setAlert({
            timeout: longWait,
            message: 'Gift failed',
            error: e.message,
            extraMessage: e.message.includes('insufficient') ? insufficientFundsExtraMessage(fund.id) : '',
            severity: 'error',
          })
          throw e
        }
      }

      let grantRequests
      try {
        grantRequests = await createGrantRequestWithRetry(MAX_RETRIES)
      } catch (e) {
        return
      }

      // Success
      dispatchStep({
        action: 'NEXT',
        state: {
          success: {
            fund,
            fundId: Number(fund.id),
            charityName: charity.accountName,
            paymentMethodType,
            isRecurring,
            recurringInterval,
            processingDate,
            grantTotal: amounts.total,
            securities: amountType === 'securities' ? securities : [],
            payment: stepperState.payment,
            contributions,
            grantRequests,
          },
        },
      })
    } catch (e) {
      setAlert({
        timeout: longWait,
        message: 'Something went unexpectedly wrong while making the donation',
        error: e.message,
        severity: 'error',
      })
    }
  }

  const onSubmitGuest = async (anonymous) => {
    try {
      const {
        amountType,
        securities,
        isRecurring,
        recurringInterval,
        processingDate,
        purposeOfGrant,
        purposeOfGrantNote,
        selfAttestation,
      } = stepperState.giftDetails
      const amounts = calculateGiftAmount(stepperState.giftDetails)
      const { paymentMethodType } = stepperState.payment

      const PaymentMethodMeta = GuestPaymentMethodsMeta[paymentMethodType] ?? {}

      let contributions
      if (paymentMethodType !== 'wallet') {
        try {
          invariant(
            PaymentMethodMeta.createContributions,
            `Payment Method type ${paymentMethodType} is missing createContributions`
          )

          contributions = await PaymentMethodMeta.createContributions({
            client,
            fundId: Number(stepperState?.fundId),
            giftDetails: stepperState?.giftDetails,
            payment: stepperState?.payment,
            userId: stepperState?.user?.id,
          })
        } catch (e) {
          setAlert({
            message: `Failed to create a contribution: ${e.message ?? ''}`,
            error: e.message,
            severity: 'error',
          })
          return
        }

        if (
          contributions?.some(
            (contribution) =>
              (contribution?.state !== 'cleared' && contribution?.state !== 'scheduled') ||
              contribution?.netAmount == null ||
              contribution?.fee == null
          )
        ) {
          setAlert({
            message:
              'The payment has not finished processing. Check your activity page and try giving from your wallet when the contribution has been processed.',
            extraMessage: (
              <Text.AltBody>
                Check your <Link to={`/funds/${stepperState.fundId}/activity`}>activity page</Link> and{' '}
                <Link to="/">give to the charity</Link> when the contribution has been processed.
              </Text.AltBody>
            ),
            severity: 'error',
          })
          return
        }
      }

      const MAX_RETRIES = 3
      const handleGuestGrantRequestCreation = async (contributionsData) => {
        let grantRequests
        if (paymentMethodType === 'wallet') {
          await client.mutate({
            mutation: mutations.grantRequests.createGuestGrantRequest,
            variables: {
              userId: stepperState?.user?.id,
              data: {
                charityId: charity.id,
                fundId: Number(stepperState.fundId),
                amount: amounts.amount,
                tip: amounts.tip,
                grantNote: join([purposeOfGrant, purposeOfGrantNote], ': '),
                isRecurring,
                recurringInterval: isRecurring ? recurringInterval : null,
                purposeOfGiftId: getPurposeOfGiftId(purposeOfGrant),
                processingDate,
                anonymous,
                selfAttestation,
                isStepperFlow: true,
              },
            },
          })
        } else if (['e-transfer', 'cheque', 'daf-transfer'].includes(paymentMethodType)) {
          grantRequests = await Promise.all(
            contributionsData.map(
              (contribution) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve({
                      data: {
                        createGrantRequest: {
                          id: Math.floor(Math.random() * 10000), // Fake ID
                          amount: contribution.amount, // Use the contribution amount
                          status: 'success', // Example status
                          note: 'This is a mocked grant request.', // Example note
                        },
                      },
                    })
                  }, 100) // Simulate network delay
                })
            )
          )
        } else {
          grantRequests = await Promise.all(
            contributionsData.map((contribution) =>
              client.mutate({
                mutation: mutations.grantRequests.createGuestGrantRequest,
                variables: {
                  userId: stepperState?.user?.id,
                  data: {
                    charityId: charity.id,
                    fundId: Number(stepperState.fundId),
                    amount:
                      amountType === 'securities'
                        ? contribution.amount
                        : decimalFromCad(
                            subtract(
                              cadFromDecimal({ amount: amounts.amount }),
                              cadFromDecimal({ amount: contribution.fee })
                            )
                          ),
                    tip: amounts.tip,
                    grantNote: join([purposeOfGrant, purposeOfGrantNote], ': '),
                    isRecurring,
                    recurringInterval: isRecurring ? recurringInterval : null,
                    purposeOfGiftId: getPurposeOfGiftId(purposeOfGrant),
                    processingDate,
                    anonymous,
                    selfAttestation,
                    isStepperFlow: true,
                  },
                },
              })
            )
          )
        }
        return grantRequests
      }

      const createGuestGrantRequestWithRetry = async (retriesLeft) => {
        try {
          return await handleGuestGrantRequestCreation(contributions)
        } catch (e) {
          if (retriesLeft > 0) {
            console.warn(`Retrying guest grant request... (Retries left: ${retriesLeft})`)
            return createGuestGrantRequestWithRetry(retriesLeft - 1)
          }
          setAlert({
            timeout: longWait,
            message: 'Gift failed',
            error: e.message,
            extraMessage: e.message.includes('insufficient') ? insufficientFundsExtraMessage(stepperState.fundId) : '',
            severity: 'error',
          })
          throw e
        }
      }

      // Create the grant
      let grantRequests
      try {
        grantRequests = await createGuestGrantRequestWithRetry(MAX_RETRIES)
      } catch (e) {
        return
      }

      // Success
      dispatchStep({
        action: 'NEXT',
        state: {
          success: {
            // fund,
            fundId: Number(stepperState.fundId),
            charityName: charity.accountName,
            paymentMethodType,
            isRecurring,
            recurringInterval,
            processingDate,
            grantTotal: amounts.total,
            securities: amountType === 'securities' ? securities : [],
            payment: stepperState.payment,
            contributions,
            grantRequests,
          },
        },
      })
    } catch (e) {
      setAlert({
        timeout: longWait,
        message: 'Something went unexpectedly wrong while making the donation',
        error: e.message,
        severity: 'error',
      })
    }
  }

  const onSubmit = async ({ anonymous }) => {
    if (stepperState.isGuest) {
      await onSubmitGuest(anonymous)
    } else {
      await onSubmitUser(anonymous)
    }
  }

  const amounts = calculateGiftAmount(stepperState.giftDetails)

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isSubmitting} style={{ display: 'contents', border: 0, p: 0, m: 0 }}>
          <Stack direction="column" spacing={2}>
            <Typography component="h2" variant="h6">
              Review and Confirm
            </Typography>

            <ContentBox border>
              <Stack direction="column" spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
                {stepperState.giftDetails.amountType === 'securities' ? (
                  <Stack direction="column" spacing={1}>
                    {stepperState.giftDetails.securities.map((sec, index) => {
                      const quantity = fromAmountField(sec.quantity || '0')
                      const amount = fromAmountField(sec.amount || '0')

                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Stack key={index} direction="row" spacing={2} justifyContent="space-between">
                          <Stack direction="column" spacing={0}>
                            <Typography>{`${sec.securitiesDescription}`}</Typography>
                            <Typography color="textSecondary" variant="body2">
                              ({`${sec.fundSymbolCUSIP}`})
                            </Typography>
                          </Stack>
                          <Stack direction="column" spacing={0} alignItems="flex-end">
                            <Typography>{quantity} Units</Typography>
                            <Typography color="textSecondary" variant="body2">
                              ~{toAmountString(amount)}
                            </Typography>
                          </Stack>
                        </Stack>
                      )
                    })}

                    <Divider orientation="horizontal" />

                    <Stack direction="row" spacing={2} justifyContent="space-between">
                      <Typography>Approx Value</Typography>
                      <Typography>{toAmountString(amounts.amount)}</Typography>
                    </Stack>
                  </Stack>
                ) : (
                  <>
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                      <Typography>Donation Amount</Typography>
                      <Typography>{toAmountString(amounts.amount)}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                      <Typography>Fees Covered</Typography>
                      <Typography>{toAmountString(amounts.tip)}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                      <Typography>Total Amount</Typography>
                      <Typography>{toAmountString(amounts.total)}</Typography>
                    </Stack>
                  </>
                )}
                <Stack>
                  <Stack direction="column" spacing={0}>
                    <Typography variant="caption" color="text.secondary">
                      Donation scheduled for:
                    </Typography>
                    <Typography>
                      {stepperState.giftDetails?.processingDate
                        ? toDateString(new Date(stepperState.giftDetails.processingDate))
                        : 'Now'}
                    </Typography>
                  </Stack>
                  <Stack direction="column" spacing={0}>
                    <Typography variant="caption" color="text.secondary">
                      Donation Interval
                    </Typography>
                    <Typography>
                      {stepperState.giftDetails?.isRecurring
                        ? { monthly: 'Monthly', quarterly: 'Quarterly', yearly: 'Annually' }[
                            stepperState.giftDetails.recurringInterval
                          ] || stepperState.giftDetails.recurringInterval
                        : 'One-Time'}
                    </Typography>
                  </Stack>
                  <Stack direction="column" spacing={0}>
                    <Typography variant="caption" color="text.secondary">
                      Gift Purpose
                    </Typography>
                    <Typography>{stepperState.giftDetails?.purposeOfGrant}</Typography>
                  </Stack>
                  {stepperState.giftDetails?.purposeOfGrantNote && (
                    <Stack direction="column" spacing={0}>
                      <Typography variant="caption" color="text.secondary">
                        Note
                      </Typography>
                      <Typography>{stepperState.giftDetails?.purposeOfGrantNote}</Typography>
                    </Stack>
                  )}
                </Stack>
                <Stack spacing={2}>
                  <Text.Body variant="body2">
                    By completing this donation, you agree to the{' '}
                    <Link
                      href="https://www.givewise.ca/terms-conditions"
                      target="_blank"
                      variant="body2"
                      data-testid="termConditionLink"
                    >
                      Terms and Conditions
                    </Link>{' '}
                    and{' '}
                    <Link
                      href="https://www.givewise.ca/privacy-policy"
                      target="_blank"
                      variant="body2"
                      data-testid="termConditionLink"
                    >
                      Privacy Policy
                    </Link>{' '}
                    of GiveWise Foundation Canada, a registered charity BN #701032526 RR 0001.
                  </Text.Body>

                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...register('anonymous')}
                          checked={getValues('anonymous')}
                          onChange={(e) => {
                            setValue('anonymous', e.target.checked, { shouldValidate: true })
                          }}
                        />
                      }
                      label="I wish to remain anonymous"
                    />
                  </FormControl>
                </Stack>
              </Stack>

              <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
            </ContentBox>

            <Stack direction="row" spacing={2}>
              <Button type="button" onClick={() => dispatchStep({ action: 'PREV' })} disabled={isSubmitting}>
                Back
              </Button>
              <Box flexGrow={1} />
              <Button type="submit" startIcon={<Icons.Lock style={{ fontSize: '1.25em' }} />} disabled={isSubmitting}>
                {stepperState.giftDetails.isRecurring ? 'Schedule Gift' : 'Give Now'}
              </Button>
            </Stack>
          </Stack>
        </fieldset>
      </form>
    </Container>
  )
}
