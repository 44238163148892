import { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'

import { queries } from '../../graphql'
import {
  BlockQuote,
  Button,
  CardBox,
  CharitiesSearchInput,
  Container,
  FormControlLabel,
  IconButton,
  Icons,
  Link,
  RecentAndFavouriteCharities,
  Row,
  Stack,
  Switch,
  Text,
  useAlert,
} from '../../components'
import { useSearchParams } from '../../hooks'
import { Results } from './search-result'
import { GenerosityFundSearchResults } from './generosity-fund-search-results'
import { CategoryFilter } from './category-filter'

export function SearchByText() {
  const [isOpen, setIsOpen] = useState(false)
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [search, setSearchParams] = useSearchParams()
  const text = typeof search.s === 'string' ? search.s : ''
  const fav = search.f === 'true'
  const p = Number(search.p)
  const page = !Number.isNaN(p) ? p : 0
  const noSearchInput = text === ''
  const {
    register,
    handleSubmit,
    formState: { errors = [] } = {},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const { data: { searchGenerosityFunds = [] } = {}, loading: generosityFundsLoading } = useQuery(
    queries.funds.searchGenerosityFunds,
    {
      variables: {
        searchString: text,
      },
    }
  )
  const [searchCharities, { loading, data } = {}] = useLazyQuery(queries.charities.searchCharities, {
    errorPolicy: 'all',
    onError: (r) => {
      setAlert({ message: 'Could complete this search', error: r.message, severity: 'error' })
    },
  })

  const [selectedCategoryCodes, setSelectedCategoryCodes] = useState([
    '0001',
    '0002',
    '0010',
    '0011',
    '0012',
    '0013',
    '0015',
    '0014',
    '0030',
    '0040',
    '0050',
    '0060',
    '0070',
    '0080',
    '0090',
    '0100',
    '0110',
    '0120',
    '0130',
    '0140',
    '0150',
    '0155',
    '0160',
    '0170',
    '0175',
    '0180',
    '0190',
    '0200',
    '0210',
    '0214',
    '0215',
  ])
  useEffect(() => {
    searchCharities({
      variables: { search: text, page, onlyShowFavourites: fav, categoryCodes: selectedCategoryCodes },
    })
  }, [text, page, fav, searchCharities, selectedCategoryCodes])

  const onSubmit = async (searchData) => {
    setSearchParams({ s: searchData?.text, p: 0, f: fav })
  }

  return (
    <Container maxWidth="lg">
      <Stack spacing={1}>
        <CardBox>
          <Stack>
            <Text.H4>Search For a Charity</Text.H4>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <Stack>
                <CharitiesSearchInput
                  error={!!errors?.text}
                  helperText={errors?.text?.message}
                  defaultValue={text}
                  {...register('text')}
                />

                <CategoryFilter setSelectedCategoryCodes={setSelectedCategoryCodes} />

                <Row justifyContent="space-between">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={fav}
                        onChange={(e) => {
                          setSearchParams({
                            s: text,
                            p: page,
                            f: e.target.checked,
                          })
                        }}
                      />
                    }
                    label="Only show my favourites"
                  />
                  {/* <Button to="?f=true">
                    <Icons.FavoriteBorder style={{ color: 'var(--dark-blue)', marginRight: '10px' }} />
                    All My Favourites
                  </Button> */}
                </Row>
                <Alert sx={{ alignSelf: 'flex-end' }} {...alertProps} />
              </Stack>
            </form>
          </Stack>
        </CardBox>
      </Stack>

      <Stack style={{ marginTop: '20px' }}>
        <CardBox>
          {noSearchInput && !fav ? null : (
            // <>
            //   {!generosityFundsLoading && searchGenerosityFunds.length && !fav ? (
            //     <GenerosityFundSearchResults
            //       loading={generosityFundsLoading}
            //       page={page}
            //       onPageChange={(newPage) => {
            //         setSearchParams({ s: text, p: newPage, f: fav })
            //       }}
            //       funds={searchGenerosityFunds}
            //       count={searchGenerosityFunds.length}
            //     />
            //   ) : (
            //     ''
            //   )}
            <Results
              loading={loading}
              page={page}
              onPageChange={(newPage) => {
                setSearchParams({ s: text, p: newPage, f: fav })
              }}
              charities={data?.searchCharities?.data}
              count={data?.searchCharities?.count}
              funds={!fav ? searchGenerosityFunds : []}
              fundsCount={!fav ? searchGenerosityFunds?.length : 0}
            />
            // </>
          )}
        </CardBox>
      </Stack>
    </Container>
  )
}
