import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { queries } from '../graphql'
import { LinearProgress } from '../components'
import { Charity } from './charity'

export function CharityLanding() {
  const { charityId, fundId } = useParams()
  const [err, setErr] = useState(null)
  const { data: { getAvailableXwikiGenerated: generated } = {}, loadingGenerated } = useQuery(
    queries.charities.getAvailableXwikiGenerated,
    {
      onError: (error) => {
        console.log('error', error)
        setErr(error)
      },
    }
  )
  const navigate = useNavigate()

  useEffect(() => {
    console.log(
      `${process.env.REACT_APP_XWIKI_URL}/bin/view/GeneratedCharityPages/${charityId}?xpage=plain&htmlHeaderAndFooter=true`
    )
    window.addEventListener('message', (event) => {
      if (event.origin === `${process.env.REACT_APP_XWIKI_URL}`) {
        if (event.data === 'Your Message') {
          navigate(`/funds/${fundId}/grant/${charityId}/give`)
        }
      }
    })
  }, [])

  if (err) {
    navigate(`/funds/${fundId}/grant/${charityId}/give`)
  }

  if (generated) {
    if (generated.indexOf(charityId) > -1) {
      return (
        <>
          <LinearProgress id="landing-page-progress" />
          <div style={{ margin: '25px' }}>
            <iframe
              title="charity-landing-page"
              style={{
                border: 'none',
                height: '1400px',
                width: '100%',
                padding: '10px',
              }}
              src={`${process.env.REACT_APP_XWIKI_URL}/bin/view/GeneratedCharityPages/${charityId}?xpage=plain&htmlHeaderAndFooter=true`}
              onLoad={() => {
                document.getElementById('landing-page-progress').style.display = 'none'
              }}
            />
          </div>
        </>
      )
    }
  } else {
    return <LinearProgress id="landing-page-progress" />
  }

  return <Charity />
}
