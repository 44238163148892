import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApolloClient, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'

import { mutations, queries } from '../../graphql'
import {
  Box,
  Button,
  CancelButton,
  CardBox,
  Container,
  DataGrid,
  EditButton,
  FormControlLabel,
  FormGroup,
  Icons,
  Link,
  Row,
  Stack,
  Switch,
  Tab,
  Tabs,
  Text,
  useAlert,
} from '../../components'
import { formatContributionDescription, titleCase, toAmountString, toDateString } from '../../utils'
import * as Card from '../../components/payment/credit-card'
import * as Bank from '../../components/payment/bank-transfer'
import { PAYMENT_TYPE } from '../../constants'
import { Filters } from './filters'

export function Recurrent() {
  const [showRContributions, setShowRContributions] = useState(true)
  const [showRGrantRequests, setShowRGrantRequests] = useState(true)
  const [showRDafTransfers, setShowRDafTransfers] = useState(true)
  // const [edit, setEdit] = useState(null)
  const { fundId } = useParams()
  const { loading, data: { me } = {} } = useQuery(queries.funds.myRecurring, {
    variables: { fundId: Number(fundId) },
  })

  const gridApi = useRef()
  const [selection, setSelection] = useState([])
  const selectSingleRow = (id) => gridApi.current.selectRows([id], /* isSelected */ true, /* resetSelection */ true)
  const clearSelection = () => gridApi.current.selectRows([], /* isSelected */ true, /* resetSelection */ true)
  const edit = useMemo(
    () => (selection.length ? me?.fund?.recurrentContributions?.find((c) => `c${c.id}` === selection[0]) : null),
    [selection, me]
  )

  const rContributions = showRContributions
    ? me?.fund?.recurrentContributions
        .filter((c) => c.state === 'active')
        .map((rc) => ({
          ...rc,
          id: `c${rc.id}`,
          recordId: rc.id,
          next: rc.nextScheduledCharge,
          description: (
            <>
              {formatContributionDescription(rc)}
              <Box sx={{ display: 'flex', justifyContent: 'end', flexGrow: 1 }}>
                <Button variant="text" sx={{ color: 'var(--dark-blue)' }} onClick={() => selectSingleRow(`c${rc.id}`)}>
                  <Icons.EditPayment />
                  <Text.SR>idk</Text.SR>
                </Button>
              </Box>
            </>
          ),
        })) || []
    : []
  const rGrantRequests = showRGrantRequests
    ? me?.fund?.recurrentGrantRequests
        .filter((g) => g.state === 'active')
        .map((rgr) => ({
          ...rgr,
          id: `c${rgr.id}`,
          recordId: rgr.id,
          next: rgr.nextScheduledGrant,
          description: (
            <Text.AltBody>
              {rgr?.charity && (
                <Link to={`/funds/${fundId}/grant/${rgr?.charity?.id}`}>{rgr?.charity?.accountName}</Link>
              )}
              {rgr?.grantNote && `${rgr.charity && ', '}${rgr?.grantNote}`}
            </Text.AltBody>
          ),
        })) || []
    : []
  const rDafTransfers = showRDafTransfers
    ? me?.fund?.recurrentDafTransfers
        .filter((c) => c.state === 'active')
        .map((rdt) => ({
          ...rdt,
          id: `c${rdt.id}`,
          recordId: rdt.id,
          next: rdt.nextScheduledTransfer,
          description: (
            <>
              <Text.AltBody>
                {rdt?.receivingFund && (
                  <>
                    <span>To: </span>
                    <Link to={`/funds/${fundId}/share-funds-with-generosity-fund/${rdt?.receivingFund?.id}`}>
                      {rdt?.receivingFund?.name}
                    </Link>
                  </>
                )}
              </Text.AltBody>
              {/* This button below is hidden only to maintain the vertical
              spacing consistency with other rows in the table */}
              <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Button
                  variant="text"
                  sx={{ color: 'var(--dark-blue)', visibility: 'hidden' }}
                  onClick={() => selectSingleRow(`c${rdt.id}`)}
                >
                  <Icons.EditPayment />
                </Button>
              </Box>
            </>
          ),
        })) || []
    : []

  const rows = [...rContributions, ...rGrantRequests, ...rDafTransfers]
  const columns = [
    {
      field: '__typename',
      headerName: 'Transaction Type',
      hideable: true,
      flex: 0.5,
      align: 'center',
      minWidth: 50,
      renderCell: ({ row } = {}) => <Icons.ActivityItem row={row} />,
      headerClassName: 'hide-header-content',
    },
    {
      field: 'amount',
      headerName: 'Amount',
      pinnable: true,
      hideable: true,
      flex: 1,
      minWidth: 105,
      valueFormatter: ({ value } = {}) => toAmountString(value),
    },
    {
      field: 'tip',
      headerName: 'Courtesy Payment',
      pinnable: true,
      hideable: true,
      flex: 1,
      valueFormatter: ({ value } = {}) => toAmountString(value),
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      flex: 1,
      minWidth: 110,
      valueFormatter: ({ value } = {}) => titleCase(value),
    },
    {
      field: 'next',
      headerName: 'Next Date',
      flex: 1,
      minWidth: 110,
      type: 'date',
      valueFormatter: ({ value } = {}) => toDateString(value),
    },
    {
      field: 'description',
      minWidth: 300,
      headerName: 'Description',
      sortable: false,
      exportable: false,
      flex: 1,
      valueGetter: ({ row }) => row?.charity?.accountName || row?.receivingFund?.name || '',
    },
    {
      field: 'charityName',
      headerName: 'Charity Name',
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) => row?.charity?.accountName || row?.receivingFund?.name || '',
    },
    {
      field: 'registeredCharityNumber',
      headerName: 'Registered Charity Number',
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) => row?.charity?.id || '',
    },
    {
      field: 'purposeOfGift',
      headerName: 'Purpose of Gift',
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) => row?.grantNote || '',
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      flex: 1,
      minWidth: 125,
      type: 'date',
      valueFormatter: ({ value } = {}) => toDateString(value),
    },
    {
      field: 'state',
      headerName: 'State',
      flex: 1,
      minWidth: 150,
      renderCell: ({ value } = {}) => <Icons.State state={value} />,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 1,
      hideable: false,
      sortable: false,
      exportable: false,
      renderCell: ({
        id,
        row: {
          __typename,
          recordId,
          state,
          amount,
          tip,
          frequency,
          nextScheduledGrant,
          nextScheduledTransfer,
          nextScheduledCharge,
          grantNote,
          anonymous,
        },
      }) => {
        if (__typename === 'RecurrentContribution') {
          return state === 'active' ? (
            <EditButton
              type="Contribution"
              mutation={mutations.contributions.userUpdateRecurrentContribution}
              refetchQueries={['MyRecurring']}
              variables={{ id: recordId, amount, nextScheduledCharge, frequency }}
            />
          ) : null
        }
        if (__typename === 'RecurrentGrantRequest') {
          return state === 'active' ? (
            <EditButton
              type="Gift"
              mutation={mutations.grantRequests.userUpdateRecurrentGrantRequest}
              refetchQueries={['MyRecurring']}
              variables={{ id: recordId, amount, tip, nextScheduledGrant, frequency, grantNote, anonymous }}
            />
          ) : null
        }
        if (__typename === 'RecurrentDafTransfer') {
          return state === 'active' ? (
            <EditButton
              type="Shared Fund"
              mutation={mutations.dafTransfers.userUpdateRecurrentDafTransfer}
              refetchQueries={['MyRecurring']}
              variables={{ id: recordId, amount, nextScheduledTransfer, frequency }}
            />
          ) : null
        }
        return null
      },
    },
    {
      field: 'cancel',
      headerName: 'Cancel',
      flex: 1,
      hideable: false,
      sortable: false,
      exportable: false,
      minWidth: 90,
      renderCell: ({ id, row: { __typename, recordId, state, amount, frequency } }) => {
        if (__typename === 'RecurrentContribution') {
          return state === 'active' ? (
            <CancelButton
              id={id}
              title="Confirm"
              details={
                <Text.AltBody>
                  <b>
                    {toAmountString(amount)}: {frequency}
                  </b>
                </Text.AltBody>
              }
              mutation={mutations.contributions.cancelRecurringContribution}
              refetchQueries={['MyFund', 'MyLinkedBanks', 'MyCreditCards']}
              variables={{ id: recordId }}
            />
          ) : null
        }
        if (__typename === 'RecurrentGrantRequest') {
          return state === 'active' ? (
            <CancelButton
              id={id}
              title="Confirm"
              details={
                <Text.AltBody>
                  <b>
                    {toAmountString(amount)}: {frequency}
                  </b>
                </Text.AltBody>
              }
              mutation={mutations.grantRequests.cancelRecurringGrantRequest}
              refetchQueries={['MyFund']}
              variables={{ id: recordId }}
            />
          ) : null
        }
        if (__typename === 'RecurrentDafTransfer') {
          return state === 'active' ? (
            <CancelButton
              id={id}
              title="Confirm"
              details={
                <Text.AltBody>
                  <b>
                    {toAmountString(amount)}: {frequency}
                  </b>
                </Text.AltBody>
              }
              mutation={mutations.dafTransfers.cancelRecurrentDafTransfer}
              refetchQueries={['MyFund']}
              variables={{ id: recordId }}
            />
          ) : null
        }
        return null
      },
    },
  ]

  return (
    <>
      <Container maxWidth="lg">
        <CardBox>
          <DataGrid
            sx={{
              width: '100%',
              border: 'none',
              '& .hide-header-content .MuiDataGrid-columnHeaderTitle': {
                display: 'none',
              },
            }}
            componentsProps={{
              toolbar: {
                csvOptions: {
                  fields: columns
                    .filter((col) => col.field !== 'edit' && col.field !== 'cancel' && col.field !== 'description')
                    .map((col) => col.field),
                },
              },
            }}
            apiRef={gridApi}
            // isRowSelectable={({ row: { __typename } }) => __typename === 'RecurrentContribution'}
            onSelectionModelChange={setSelection}
            disableSelectionOnClick
            autoRowHeight
            loading={loading}
            rows={rows}
            columns={columns}
            filters={
              <Filters
                showRGrantRequests={showRGrantRequests}
                setShowRGrantRequests={setShowRGrantRequests}
                showRContributions={showRContributions}
                setShowRContributions={setShowRContributions}
                showRDafTransfers={showRDafTransfers}
                setShowRDafTransfers={setShowRDafTransfers}
              />
            }
            initialState={{
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }],
              },
              columns: {
                columnVisibilityModel: {
                  state: false,
                  charityName: false,
                  registeredCharityNumber: false,
                  purposeOfGift: false,
                },
              },
            }}
          />
        </CardBox>
      </Container>
      {edit && <UpdatePaymentMethod contribution={edit} close={clearSelection} />}
    </>
  )
}

function UpdatePaymentMethod({ contribution, close }) {
  const {
    id,
    fundId,
    paymentMethod: { paymentType },
  } = contribution
  const client = useApolloClient()
  const cardApi = useRef()
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({ mode: 'onSubmit', defaultValues: { paymentMethodId: contribution.paymentMethod.id } })

  /* either credit-card or bank-transfer */
  const [tab, setTab] = useState('credit-card')

  /* when the user switches the selected contribution, reset the form with new default */
  useEffect(() => reset({ paymentMethodId: contribution.paymentMethod.id }), [reset, contribution.paymentMethod.id])
  /* when the current payment type changes, switch tabs in order to show the current payment method */
  useEffect(() => setTab(paymentType === PAYMENT_TYPE.bankTransfer ? 'bank-transfer' : 'credit-card'), [paymentType])

  const onSubmit = async ({ multiUse, paymentMethodId: formPaymentMethodId }) => {
    let newPayment = Number(formPaymentMethodId)

    if (tab === 'credit-card' && cardApi.current.isNewCard) {
      try {
        newPayment = await cardApi.current.postToStripeAndSavePaymentMethod({ multiUse })
      } catch (e) {
        setAlert({
          message: `Something went wrong using this card`,
          error: e.message || e.type,
          severity: 'error',
        })
        return
      }
    }

    await client.mutate({
      mutation: mutations.contributions.updateRecurrentContributionPaymentMethod,
      variables: { id, paymentMethodId: newPayment },
      refetchQueries: [{ query: queries.funds.myRecurring, variables: { fundId } }],
      awaitRefetchQueries: true,
    })

    close()
  }

  return (
    <Container maxWidth="lg">
      <Text.H6>Update Payment Method</Text.H6>
      <Tabs value={tab} onChange={(_, v) => setTab(v)}>
        <Tab label="Credit Card" value="credit-card" />
        <Tab label="Bank Transfer" value="bank-transfer" />
      </Tabs>
      <CardBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {tab === 'credit-card' ? (
              <>
                <Card.Select ref={cardApi} register={register} />
                <Card.FeesNotice />
              </>
            ) : (
              <>
                <Bank.FAQ />
                <Bank.Select register={register} />
              </>
            )}
            <Row sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button disabled={isSubmitting} onClick={close} color="info">
                Back
              </Button>
              <Button disabled={isSubmitting} type="submit">
                Confirm
              </Button>
            </Row>
            <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
          </Stack>
        </form>
      </CardBox>
    </Container>
  )
}
