import { useEffect, useState } from 'react'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { CircularProgress, Icon, Box } from '@mui/material'

import { BankAccountSetup } from '../../zumrails-iframe'
import { mutations, queries } from '../../../graphql'
import {
  Acknowledge,
  Button,
  CardBox,
  Container,
  Dialog,
  Fields,
  InputAdornment,
  LinearProgress,
  Stack,
  Scheduled,
  Recurring,
  useAlert,
  useDialog,
  validations,
  Iframe,
  Text,
  Icons,
  Row,
} from '../../../components'
import * as Bank from '../../../components/payment/bank-transfer'
import { fromAmountField } from '../../../utils'

export function BankTransferZum({ isCharityPortal = false }) {
  const { data: { userHasBankAccount } = {}, loading: userHasBankAccountLoading } = useQuery(
    queries.paymentMethods.userHasBankAccount,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  )

  const { data: userData } = useQuery(queries.user.me)
  const userType = userData?.me?.profile?.userType

  const content = () => {
    if (userHasBankAccountLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <CircularProgress color="info" />
          <div style={{ marginLeft: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Text.Body>One moment...</Text.Body>
          </div>
        </div>
      )
    }

    if (userHasBankAccount === undefined) {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <CircularProgress color="info" />
          <div style={{ marginLeft: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Text.Body>Loading bank account information...</Text.Body>
          </div>
        </div>
      )
    }

    if (userHasBankAccount) {
      return <BankTransferForm />
    }

    return <BankAccountSetup />
  }

  // If it's being rendered from CharityPortal, skip the extra container/cardbox
  if (isCharityPortal) {
    return <Stack spacing={4}>{content()}</Stack>
  }

  return (
    <Container maxWidth="lg">
      <CardBox title="Bank Transfer">
        <Stack spacing={4}>
          <Bank.FAQ />
          {content()}
        </Stack>
      </CardBox>
    </Container>
  )
}

function BankTransferForm({ setIsNewBankAccount, linkedBanks = [] }) {
  const { fundId } = useParams()
  const { data: { me: { fund } = { fund: {} } } = {} } = useQuery(queries.funds.myFund)
  const { data: userData } = useQuery(queries.user.me)
  const profile = userData?.me?.profile || {}
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const {
    data: { linkedZumRailsBanks = [] } = {},
    loading: loadingZumRailsBanks,
    refetch: refetchLinkedZumRailsBanks,
  } = useQuery(queries.paymentMethods.linkedZumRailsBanks, {
    fetchPolicy: 'network-only',
  })
  const [{ dialogProps }, { open: openDialog, close: closeDialog }] = useDialog()
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset: resetForm,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: 0,
      isRecurring: false,
      recurringInterval: 'monthly',
    },
  })
  const closeSuccessDialog = () => {
    closeDialog()
    resetForm()
  }
  const [createContribution, { data, loading }] = useMutation(mutations.contributions.createContribution, {
    refetchQueries: [{ query: queries.funds.myFundActivity, variables: { fundId: Number(fundId) } }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setIsSubmitting(false)
      openDialog()
    },
    onError: (e) => {
      setIsSubmitting(false)
      setAlert({
        message: `Failed to create a contribution: ${e.message ?? ''}`,
        error: e.message,
        severity: 'error',
      })
      resetForm()
    },
  })
  const { errors = {} } = formState
  const onSubmit = async ({ amount, isRecurring, recurringInterval, processingDate }) => {
    setIsSubmitting(true)
    const contributionObj = {
      paymentMethodId: Number(linkedZumRailsBanks?.[0]?.PaymentMethodId),
      fundId: Number(fund.id),
      amount: fromAmountField(amount),
      isRecurring,
      recurringInterval,
      processingDate,
      processor: 'zumrails',
    }

    await createContribution({
      variables: {
        data: contributionObj,
      },
    })
  }
  console.log(fund)
  const { Institution, AccountNumber } = linkedZumRailsBanks.length > 0 ? linkedZumRailsBanks[0] : {}

  const bankAccountInfo = () => {
    const loaderOrInfo = () => {
      if (loadingZumRailsBanks) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '8px',
            }}
          >
            <CircularProgress size={16} color="info" />
          </div>
        )
      }

      return (
        <Text.Body style={{ marginLeft: '8px', color: 'grey' }}>
          {`${Institution} - **** ${AccountNumber?.slice(-4)}`}
        </Text.Body>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '8px',
        }}
      >
        <Icons.Account />
        <Text.Body style={{ marginLeft: '8px' }}>Bank Account</Text.Body>
        {loaderOrInfo()}
      </div>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Stack spacing={2}>
          <Fields.Text
            label="Amount"
            name="amount"
            error={!!errors?.amount}
            helperText={errors?.amount?.message}
            InputProps={{
              inputComponent: Fields.Amount,
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputProps: {
                setValue: (value) => setValue('amount', value),
                getValue: () => getValues('amount'),
                defaultValue: 0,
              },
            }}
            {...register('amount', {
              ...{ min: { value: 1, message: 'Must be at least 1' } },
              ...validations.required,
              ...validations.number,
            })}
          />
          {bankAccountInfo()}
          {/* <Bank.SavedAccounts register={register} linkedBanks={linkedBanks} setIsNewBankAccount={setIsNewBankAccount} /> */}

          <Scheduled register={register} errors={errors} control={control} getValues={getValues} setValue={setValue} />
          <Recurring register={register} errors={errors} getValues={getValues} setValue={setValue} />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="text" to="/profile#manage-bank-accounts" style={{ marginTop: 0 }}>
              Manage Bank Accounts
            </Button>
          </Box>

          <Button type="submit" sx={{ alignSelf: 'flex-end' }} disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
          <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
        </Stack>
      </form>
      <Dialog {...dialogProps} onClose={closeSuccessDialog}>
        <Acknowledge.Success title="Success!" content="You have successfully created a contribution.">
          {profile.userType === 'charity' ? (
            <Button onClick={closeSuccessDialog}>Done</Button>
          ) : (
            <Button to={`/funds/${fund.id}/activity/giving-wallet`}>Done</Button>
          )}
        </Acknowledge.Success>
      </Dialog>
    </>
  )
}
