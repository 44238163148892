import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

import { Container, Stack } from '../../components'
import { toAmountString } from '../../utils'
import { PaymentMethodsMeta } from './payment'
import { GuestPaymentMethodsMeta } from './payment-guest'
import { onlyShowInDev } from './showUnfinishedFeatures'

export function SuccessStep({ charity, me, stepperState }) {
  useEffect(() => {
    if (onlyShowInDev) console.log(`success stepperState: ${JSON.stringify(stepperState, null, 2)}`)
  }, [stepperState])
  const { fundId, paymentMethodType, isRecurring, processingDate, grantTotal } = stepperState.success

  const PaymentMethodMeta =
    (stepperState.isGuest ? GuestPaymentMethodsMeta[paymentMethodType] : PaymentMethodsMeta[paymentMethodType]) ?? {}

  // const [requestPasswordReset, { loading, data, reset: resetMutation }] = useMutation(
  //   mutations.user.requestPasswordReset,
  //   {
  //     onCompleted: () => {},
  //   }
  // )

  return (
    <Container maxWidth="sm" sx={{ flex: 1, py: 2, display: 'flex', flexDirection: 'column' }}>
      <Stack direction="column" flexGrow={1} justifyContent="center">
        {PaymentMethodMeta.SuccessUI && <PaymentMethodMeta.SuccessUI me={me} {...stepperState.success} />}

        {/* @note This is not needed until the Donor Info step has sign-up embedded */}
        {/* <Divider direction="horizontal" />
  
		  <Stack direction="column" spacing={2} alignItems="center" sx={{ mt: 4 }}>
			<Text.Body>
			  You’re almost there! Add a password to your Giving Fund so that you can log in when you return.{' '}
			</Text.Body>
  
			<Button color="info" onClick={() => requestPasswordReset(me.email)}>
			  Add Password
			</Button>
		  </Stack> */}
      </Stack>
    </Container>
  )
}
