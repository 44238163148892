import React, { useEffect, useState } from 'react'
import {
  TextField,
  Button,
  Box,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Typography,
  Checkbox,
  Tabs,
  Tab,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router'

import { mutations, queries } from '../../graphql'

const DEFAULT_REASONS = ['General Support', 'In Memory Of', 'Other']

function DonationReasons() {
  const [tabIndex, setTabIndex] = useState(0)
  const { id: charityId } = useParams()
  const { data: { charity } = {}, charityLoading } = useQuery(queries.charities.myCharityActivity, {
    variables: { charityId },
  })
  const [deleteReasonId, setDeleteReasonId] = useState(null)
  const [updateReasons, setUpdateReasons] = useState([])
  const [dirty, setDirty] = useState(false)
  const [donationReasons, setDonationReasons] = useState([])
  const maxReasons = 15
  const remainingReasons = charity?.purposeOfGifts ? maxReasons - charity.purposeOfGifts.length : maxReasons

  const [createPurposeOfGift] = useMutation(mutations.charities.createPurposeOfGift, {
    variables: {
      data: {
        reason: '',
        charityId: charity?.id,
      },
    },
    refetchQueries: [
      {
        query: queries.charities.myCharityActivity,
        variables: { charityId },
      },
    ],
  })
  const [deletePurposeOfGift] = useMutation(mutations.charities.deletePurposeOfGift, {
    variables: {
      id: deleteReasonId,
    },
    refetchQueries: [
      {
        query: queries.charities.myCharityActivity,
        variables: { charityId },
      },
    ],
  })
  const [updatePurposeOfGift] = useMutation(mutations.charities.updatePurposeOfGift, {
    variables: {
      data: updateReasons.map((r) => ({
        id: r?.id,
        reason: r?.reason,
        active: r?.active,
      })),
    },
    refetchQueries: [
      {
        query: queries.charities.myCharityActivity,
        variables: { charityId },
      },
    ],
  })

  useEffect(() => {
    if (charity?.purposeOfGifts) {
      let reasonsToLoad = []
      if (updateReasons.length > 0) {
        charity?.purposeOfGifts.forEach((r) => {
          const updateMatch = updateReasons.find((_r) => _r.id === r.id)
          if (updateMatch) {
            reasonsToLoad.push(updateMatch)
          } else {
            reasonsToLoad.push(r)
          }
        })
      } else {
        reasonsToLoad = [...charity.purposeOfGifts]
      }

      reasonsToLoad.sort((a, b) => {
        const aIndex = DEFAULT_REASONS.indexOf(a.reason)
        const bIndex = DEFAULT_REASONS.indexOf(b.reason)
        if (aIndex === -1 && bIndex === -1) return 0
        if (aIndex === -1) return 1
        if (bIndex === -1) return -1
        return aIndex - bIndex
      })

      setDonationReasons(reasonsToLoad)
    }
  }, [charity, updateReasons])

  useEffect(() => {
    if (deleteReasonId) {
      deletePurposeOfGift()
    }
  }, [deleteReasonId])

  const handleReasonChange = (id, event, toggle = false) => {
    const reason = donationReasons.find((r) => r.id === id)

    if (DEFAULT_REASONS.includes(reason?.reason)) return

    const reasonToChange = {
      ...reason,
      [toggle ? 'active' : 'reason']: toggle ? event.target.checked : event.target.value,
    }

    setDonationReasons((prev) => prev.map((r) => (r.id === id ? reasonToChange : r)))
    const exists = updateReasons.findIndex((r) => r.id === reasonToChange.id)
    let newUpdatedReasons = [...updateReasons]
    if (exists > -1) {
      newUpdatedReasons.splice(exists, 1, reasonToChange)
    } else {
      newUpdatedReasons = [...updateReasons, reasonToChange]
    }
    setUpdateReasons(newUpdatedReasons)
    setDirty(true)
  }

  const handleAddReason = () => {
    if (donationReasons.length < maxReasons) {
      createPurposeOfGift()
    }
  }

  const handleRemoveReason = (id) => {
    const reasonToDelete = donationReasons.find((r) => r.id === id)

    if (DEFAULT_REASONS.includes(reasonToDelete?.reason)) return

    setDonationReasons((prev) => prev.filter((r) => r.id !== id))
    setDeleteReasonId(id)
  }

  const handleUpdateReason = () => {
    updatePurposeOfGift()
    setDirty(false)
  }

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex)
  }

  const customTab = () => {
    if (!donationReasons || donationReasons.length === 0) {
      return ''
    }
    return (
      <>
        {donationReasons.map((entry, index) => {
          const isDefault = DEFAULT_REASONS.includes(entry.reason)

          return (
            <Box key={entry.id} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                label={`Designation #${index + 1}`}
                variant="outlined"
                value={entry.reason}
                onChange={(event) => handleReasonChange(entry.id, event)}
                disabled={isDefault}
              />
              <Checkbox
                checked={entry.active}
                disabled={isDefault}
                onChange={(event) => handleReasonChange(entry.id, event, true)}
              />
              {!isDefault && (
                <IconButton onClick={() => handleRemoveReason(entry.id)} color="error" sx={{ ml: 1 }}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          )
        })}
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddReason}
            disabled={donationReasons.length >= maxReasons}
          >
            Add New
          </Button>
          <Typography variant="caption" color="text.secondary">
            You can add <b>{remainingReasons}</b> more Gift Designations.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateReason}
            sx={{
              display: dirty ? 'auto' : 'none',
            }}
          >
            Save
          </Button>
        </Stack>
      </>
    )
  }

  const defualtTab = () => (
    <>
      <br />
      {donationReasons
        ? donationReasons
            .filter((r) => r.isDefault)
            .map((entry, index) => (
              <Box key={entry.id} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                <Checkbox checked={entry.active} onChange={(event) => handleReasonChange(entry.id, event, true)} />
                <TextField
                  fullWidth
                  label={`Designation #${index + 1}`}
                  variant="outlined"
                  value={entry.reason}
                  onChange={(event) => handleReasonChange(entry.id, event)}
                />
                {charity?.purposeOfGifts?.length > 0 && (
                  <IconButton onClick={() => handleRemoveReason(entry.id)} color="error" sx={{ ml: 1 }}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))
        : ''}
      <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateReason}
          sx={{
            display: dirty ? 'auto' : 'none',
          }}
        >
          Save
        </Button>
      </Stack>
    </>
  )

  return (
    <Box sx={{ maxWidth: 560, mt: 3 }}>
      <Card>
        <CardHeader
          title="Purpose Of Gift"
          subheader={`Add or update the specific purposes and programs for which your charity accepts donations.
          These designations will restrict the gift to the specified purpose.`}
        />
        <CardContent>
          {/* <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Default" />
            <Tab label="Custom" />
          </Tabs> */}

          {customTab()}

          {/* <br />
          {tabIndex === 1 ? customTab() : defualtTab()} */}
        </CardContent>
      </Card>
    </Box>
  )
}

export default DonationReasons
