import { useQuery, useApolloClient } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

import ManualEntryForm from './manual-pad-form'
import { BankAccountSetup } from '../zumrails-iframe'
import {
  Card,
  CardBox,
  Container,
  Icons,
  LinearProgress,
  Text,
  Row,
  Button,
  Box,
  useConfirm,
  Stack,
  Collapse,
  BlockQuote,
  Link,
  Dialog,
  Acknowledge,
} from '../../components'
import { mutations, queries } from '../../graphql'

const BankAccountFAQ = function ({ isOpen, setIsOpen }) {
  return (
    <BlockQuote sx={{ pt: 1, pb: 1, margin: '10px' }}>
      <Row alignItems="center">
        <Button unstyled onClick={() => setIsOpen(!isOpen)}>
          <Text.Bold>FAQs</Text.Bold>
          {isOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
        </Button>
      </Row>
      <Collapse in={isOpen} unmountOnExit sx={{ mt: '0 !important' }}>
        <Text.Body>
          <ul style={{ listStyle: 'none', lineHeight: 2 }}>
            <li>
              <b>Does GiveWise store my banking information?</b> No. GiveWise does not store any of your banking
              information at all. GiveWise uses a secure banking platform called Zum Rails and no banking information is
              stored by us.
            </li>
            <li>
              <b>How do I update my Direct Deposit information?</b> Under the Direct Deposit tab, choose
              &quot;Remove&quot; from the right of the existing bank account. Once that is done, you will see the setup
              screen and a new bank account can be entered.
            </li>
          </ul>
        </Text.Body>
      </Collapse>
    </BlockQuote>
  )
}

const ExistingBankAccount = function ({ bankAccount, onRemove }) {
  const [isRemoving, setIsRemoving] = useState(false)

  const handleRemove = async () => {
    setIsRemoving(true)
    try {
      await onRemove()
    } finally {
      setIsRemoving(false)
    }
  }

  return (
    <Card
      sx={{
        padding: '25px',
        transition: 'transform 0.15s ease-in-out',
        '&:hover': { transform: 'scale3d(1.01, 1.01, 1)' },
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {isRemoving && (
        <LinearProgress
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      )}

      <Row>
        <Icons.BuildingColumns />
        <Text.H6>{bankAccount.details.Id ? bankAccount.details.Institution : bankAccount.details.institution}</Text.H6>
      </Row>

      <Row sx={{ padding: '10px 0px' }}>
        <Text.H7>Account Number</Text.H7>
        <Text.Body>{`****${bankAccount.details.last4}`}</Text.Body>
      </Row>

      <Row sx={{ padding: '15px 0px', display: 'flex', justifyContent: 'space-between' }}>
        <Text.Body sx={{ maxWidth: '800px' }}>
          If you&apos;d like to change your bank account, please remove the existing one and follow the on-screen
          instructions to add a new one.
        </Text.Body>
        <Button variant="outlined" color="error" onClick={handleRemove} disabled={isRemoving}>
          {isRemoving ? 'Removing...' : 'Remove'}
        </Button>
      </Row>
    </Card>
  )
}

export function CharityPortalDirectDeposit() {
  const apolloClient = useApolloClient()
  const { id: charityId } = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const [method, setMethod] = useState('')
  const apiHost = process.env.REACT_APP_API_HOST
  const [, { setConfirm }] = useConfirm()
  const [showSuccessDialog, setShowSuccessDialog] = useState(false)

  const { data: { userHasBankAccount } = {}, loading: checkingBankAccount } = useQuery(
    queries.paymentMethods.userHasBankAccount,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  )

  const {
    data,
    loading: loadingBankAccounts,
    refetch: refetchCharityBankAccounts,
    error,
  } = useQuery(queries.charities.getCharityBankAccounts, {
    variables: { charityId },
    fetchPolicy: 'network-only',
  })

  const handleRemoveBankAccount = async () => {
    try {
      const token = `Bearer ${JSON.parse(localStorage.getItem('givewise_auth_state'))?.state?.rawToken}`
      const response = await fetch(
        `${apiHost}/zumrails/delete-bank-account/${data.getCharityBankAccounts[0].details.zumUserId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }
      )

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}))
        throw new Error(errorData.error || response.statusText || 'Failed to delete bank account from ZumRails')
      }

      await apolloClient.refetchQueries({
        include: ['Charity', 'getCharityBankAccounts'],
      })
    } catch (err) {
      console.error('Error during bank account removal:', err)
      throw err
    }
  }

  const handleRemoveClick = () => {
    const bankAccount = data.getCharityBankAccounts[0]
    const message = (
      <Stack spacing={0}>
        <Text.Body>Please confirm that you want to remove the following bank account:</Text.Body>
        <Text.H7>
          {bankAccount.details.Id
            ? `${bankAccount.details.Institution} - ****${bankAccount.details.last4}`
            : `${bankAccount.details.institution} - ****${bankAccount.details.last4}`}
        </Text.H7>
      </Stack>
    )

    return new Promise((resolve, reject) => {
      setConfirm({
        open: true,
        title: 'Confirm',
        message,
        mutation: mutations.charities.removeCharityBankAccount,
        success: 'Bank Account has been removed from your Charity profile',
        error: 'Failed to remove bank account',
        variables: {
          id: charityId,
          paymentMethodId: bankAccount.id,
        },
        onSuccess: async () => {
          try {
            await handleRemoveBankAccount()
            resolve()
          } catch (removeError) {
            reject(removeError)
          }
        },
        onCancel: () => resolve(),
        onClose: async () => {
          try {
            await handleRemoveBankAccount()
            resolve()
          } catch (removeError) {
            reject(removeError)
          }
        },
      })
    })
  }

  const handleSetupSuccess = () => {
    refetchCharityBankAccounts()
    apolloClient.refetchQueries({
      include: ['Charity', 'getCharityBankAccounts'],
    })
    setShowSuccessDialog(true)
  }

  const handleSuccessDialogClose = () => {
    setShowSuccessDialog(false)
    setMethod('')
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Text.H1>Direct Deposit</Text.H1>
        <Text.Body color="error">Unable to load bank account information. Please try again later.</Text.Body>
      </Container>
    )
  }

  if (checkingBankAccount || loadingBankAccounts) {
    return (
      <Container maxWidth="lg">
        <Text.H1>Direct Deposit</Text.H1>
        <Box sx={{ padding: '20px 0' }}>
          <Text.Body sx={{ marginBottom: 2 }}>Please wait while we retrieve your information...</Text.Body>
          <LinearProgress sx={{ marginTop: 1 }} />
        </Box>
      </Container>
    )
  }

  const hasBankAccounts = data?.getCharityBankAccounts?.length > 0

  return (
    <Container maxWidth="lg">
      <Text.H1>Direct Deposit</Text.H1>
      <br />
      <Text.H7>
        {hasBankAccounts
          ? 'You are set up to receive your donations to the following bank account'
          : "Connect your charity's bank account to receive donations"}
      </Text.H7>
      <br />
      <br />

      {hasBankAccounts ? (
        <>
          <BankAccountFAQ isOpen={isOpen} setIsOpen={setIsOpen} />
          <ExistingBankAccount bankAccount={data.getCharityBankAccounts[0]} onRemove={handleRemoveClick} />
        </>
      ) : (
        <>
          <BankAccountFAQ isOpen={isOpen} setIsOpen={setIsOpen} />
          {!method ? (
            <CardBox>
              <Text.H6>Choose Your Preferred Method to Connect Your Bank Account:</Text.H6>

              <Box sx={{ mt: 2, mb: 1 }}>
                <Button variant="contained" onClick={() => setMethod('online')}>
                  Online Banking (Recommended)
                </Button>
              </Box>

              <Text.Body sx={{ mb: 3 }}>
                This option is fast, secure, and minimizes errors by connecting directly to your bank. We recommend this
                method for the best security and a seamless experience.
              </Text.Body>

              <Box sx={{ mb: 1 }}>
                <Button variant="contained" onClick={() => setMethod('manual')}>
                  Manual Entry
                </Button>
              </Box>

              <Text.Body>
                If you don&apos;t have access to your online banking credentials, you can manually enter your banking
                information. Please ensure that all details are entered accurately to avoid any delays or errors.
              </Text.Body>
            </CardBox>
          ) : null}

          {method === 'online' && (
            <CardBox sx={{ my: 3 }}>
              <BankAccountSetup onSuccess={handleSetupSuccess} />
            </CardBox>
          )}

          {method === 'manual' && (
            <CardBox sx={{ my: 3 }}>
              <ManualEntryForm onSuccess={handleSetupSuccess} />
            </CardBox>
          )}
        </>
      )}
      <Dialog open={showSuccessDialog} onClose={handleSuccessDialogClose} maxWidth="sm">
        <Acknowledge.Success title="Success!" content="This charity is now ready to receive funds.">
          <Button variant="contained" onClick={handleSuccessDialogClose}>
            Done
          </Button>
        </Acknowledge.Success>
      </Dialog>
    </Container>
  )
}

export default CharityPortalDirectDeposit
